<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-row class="justify-content-center">
      <v-col md="8" cols="12">
        <v-card-title style="background-color: transparent !important">
          <h3>ثبت بازخورد</h3>
        </v-card-title>

        <v-card class="cards pa-5">
          <v-card-text class="pt-1">
            <v-text-field
              label="عنوان"
              outlined
              dense
              v-model="feedback.title"
              prepend-icon="mdi-note-edit-outline"
            ></v-text-field>
            <v-textarea
              label="توضیحات"
              outlined
              dense
              v-model="feedback.description"
              color="primary"
              prepend-icon="mdi-comment-quote-outline"
              rows="10"
              shaped
            >
            </v-textarea>
            <v-file-input
              @change="fileUpload(file)"
              v-model="file"
              outlined
              dense
              label=" بارگذاری فایل"
              prepend-icon="mdi-file-outline"
            ></v-file-input>
            <div class="ms-6">
              <v-progress-linear
                rounded
                v-if="myFile.showProgress"
                height="10"
                :value="myFile.uploadPercentage"
                class="mb-4 mt-0"
              >
              </v-progress-linear>
              <v-row v-if="myFile.isUploaded" class="mb-4">
                <div
                  color="green"
                  class="mt-3 mb-1 pa-3 text-right"
                  style="font-size: 16px; line-height: 30px"
                >
                  فایل با موفقیت بارگذاری شد.
                  <br />
                  <span class="common-text">(فایل بارگذاری شده:</span>
                  <a :href="uploadedFile.url" target="_blank">
                    {{ uploadedFile.name }}</a
                  >)
                </div>
              </v-row>
            </div>

            <v-card-actions>
              <v-btn
                class="primary-btn ms-6 px-4"
                @click="submitFeedback()"
                :disabled="!feedback.title || !feedback.description"
              >
                <v-icon class="pe-2">mdi-note-check-outline</v-icon>
                <span class="common-text">ثبت</span>
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feedback: {},
      myFile: new Object({
        uploadPercentage: 0,
      }),
      file: [],
      uploadedFile: {},
    };
  },

  methods: {
    submitFeedback() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/feedback/new",
          {
            title: this.feedback.title,
            description: this.feedback.description,
            fileId: this.uploadedFile.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.vLoading = false;
            this.toast("بازخورد با موفقیت ثبت شد.", "success");
            this.feedback = {};
            this.myFile = new Object({
              uploadPercentage: 0,
            });
            this.uploadedFile = {};
            this.file = [];
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    fileUpload(file) {
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      this.uploadedFile.name = file.name;
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/feedback/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.myFile.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 200) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.uploadedFile.id = d.data.id;
            this.uploadedFile.url = d.data.url;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },
};
</script>
<style lang="scss">
.mdi-file-outline::before {
  font-size: 24px !important;
}
</style>
